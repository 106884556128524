<template>
  <v-container
    id="employees"
    fluid
    tag="section"
  >
    <v-row>
      <edit-employee-dialog
        v-if="uProfile"
        :u-profile="uProfile"
        @close="editDialogClosed"
      />
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
              >
                <base-app-text-field
                  v-model="nameToSearch"
                  label="Search"
                  append-icon="mdi-arrow-right-circle"
                  prev-icon="null"
                  next-icon="null"
                  @keyup.enter="searchByName"
                  @click:append="searchByName"
                />
              </v-col>
              <v-col
                cols="12"
                class="text-center"
              >
                <v-pagination
                  v-if="!!pages"
                  v-model="pageNum"
                  :length="pages"
                  :disabled="!(pages)"
                  @input="loadData"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="employees"
              :search="search"
              disable-pagination
              hide-default-footer
              class="cursor-pointer"
              item-key="uuid"
              @click:row="editEmp"
            >
              <template v-slot:item.agencyName="{ item }">
                <span>{{ agencyNameByExternalId(item.agencyExternalId) }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  // import CaregiverEditView from './component/caregiver/CaregiverEditView.vue'
  import EmployeeEditDialog from './component/employee/EmployeeEditDialog.vue'

  import { eventBus, busEvents } from '@/EventBus'

  export default {
    name: 'EmployeesView',
    components: {
      // CaregiverEditView,
      'edit-employee-dialog': EmployeeEditDialog,
    },
    data () {
      return {
        search: '',
        headers: [
          {
            sortable: true,
            text: 'Employee ID',
            value: 'id',
          },
          {
            sortable: true,
            text: 'firstName',
            value: 'firstName',
          },
          {
            sortable: true,
            text: 'lastName',
            value: 'lastName',
          },
          {
            sortable: true,
            text: 'Email',
            value: 'email',
          },
        ],
        dialog: false,
        currentEmployee: {},
        originalEmployee: {},
        currentEmployeeId: -1,
        currentEmployeeUUID: null,
        agencyProfile: {},
        nameToSearch: null,
        totalRows: null,
        pageSize: null,
        pageNum: 1,
        aProfile: null,
        cProfile: null,
        uProfile: null,
      }
    },
    computed: {
      ...mapGetters({
        agencyByExternalId: 'agency/AGENCY_BY_EXTERNAL_ID',
        employees: 'employee/EMPLOYEES',
      }),
      pages () {
        return (this.totalRows && this.pageSize) ? Math.ceil(this.totalRows / this.pageSize) : 0
      },
    },
    mounted () {
      this.setEmployees([])
    },
    methods: {
      ...mapMutations({
        updateAgencies: 'agency/UPDATE_AGENCIES',
        setLoading: 'app/SET_LOADING',
        setEmployees: 'employee/SET_EMPLOYEES',
      }),
      agencyNameByExternalId (externalId) {
        return this.agencyByExternalId(externalId).name
      },
      searchByName () {
        if (this.nameToSearch) {
          this.loadData()
        }
      },
      editDialogClosed (data) {
        this.aProfile = null
        this.cProfile = null
        this.uProfile = null
        if (data) {
          // update item in table
          const index = this.caregivers.indexOf(this.caregivers.find(function (element, index) {
            return element.id === data.id
          }))
          if (index > -1) {
            this.caregivers[index].firstName = data.firstName
            this.caregivers[index].middleName = data.middleName
            this.caregivers[index].lastName = data.lastName
            this.caregivers[index].email = data.email
            this.caregivers[index].staffStatus = data.staffStatus
          }
        }
        this.dialog = false
      },
      loadData () {
        this.setLoading(true)
        this.$careConnect.searchEmployees(this.nameToSearch, this.pageNum)
          .then(resp => {
            const { profiles, paging } = resp

            // Filter duplicate profiles
            const filtered = profiles
              .filter((profile, index, self) =>
                index === self.findIndex((prof) =>
                  prof.id === profile.id,
                ),
              )
              // Filter users that don't have user account created
              .filter(e => e.email)
            this.setEmployees(filtered)
            this.totalRows = paging.totalRows
            this.pageSize = paging.pageSize
            this.pageNum = paging.pageNum
          })
          .catch(err => {
            console.log(err)
            const text = err.json ? err.json.error : 'Failed to search employees'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      async editEmployee (item) {
        // get agency details
        const { id, userUuid, agencyExternalId } = item
        const agency = this.agencyByExternalId(agencyExternalId)
        this.dialog = true
        this.currentEmployeeId = id
        this.currentEmployeeUUID = userUuid
        this.agencyProfile = { id, agencyId: agency.id, agencyExternalId: agency.agencyExternalId }
      },
      editEmp (item) {
        this.setLoading(true)
        const { id, agencyExternalId } = item
        const agency = this.agencyByExternalId(agencyExternalId)

        this.$careConnect.getEmployeeAgencyProfile(id, agency.id)
          .then(resp => {
            const { agencyProfile, userProfile } = resp
            const uProfile = JSON.parse(JSON.stringify(userProfile))
            this.cProfile = userProfile ? userProfile.commonProfile : null
            this.aProfile = agencyProfile
            this.uProfile = uProfile
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to load agency profile'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
    },
  }
</script>
<style scoped>
.cursor-pointer tbody tr:hover {
  cursor: pointer;
}
</style>
