<template>
  <v-dialog
    v-model="uProfile"
    persistent
    scrollable
    width="1200"
  >
    <v-card>
      <base-app-dialog-title
        title="EDIT EMPLOYEE"
        @close="close"
      >
        <v-tabs
          v-model="tab"
        >
          <v-tab>
            User profile
          </v-tab>
          <v-tab>
            Agency roles
          </v-tab>
        </v-tabs>
      </base-app-dialog-title>
      <v-card-text class="pt-0">
        <v-form
          ref="ffa"
          v-model="valid"
        >
          <v-tabs-items v-model="tab">
            <v-tab-item
              key="1"
            >
              <v-card flat>
                <v-card-text>
                  <user-profile-tab v-model="userProfile" />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
              key="2"
            >
              <v-card flat>
                <v-card-text>
                  <v-row
                    v-for="(agency, idx) in agenciesWithRoles"
                    :key="idx"
                    align="center"
                  >
                    <v-col cols="6">
                      <span class="font-weight-light">{{ agency.name }}</span>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="agency.roles"
                        :items="agencyRoles"
                        max-width="500"
                        label="Roles"
                        multiple
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip
                            :color="item.color"
                            outlined
                          >
                            <span>{{ item.text }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="2">
                      <base-app-dialog-action-button
                        :disabled="!(newAgency && newRoles)"
                        @click="addRoles"
                      >
                        Add role
                      </base-app-dialog-action-button>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="newAgency"
                        :items="sortedAgencies"
                        item-text="name"
                        item-value="id"
                        max-width="500"
                        label="Agency"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="newRoles"
                        :items="agencyRoles"
                        max-width="500"
                        label="Roles"
                        multiple
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip
                            small
                            :color="item.color"
                            outlined
                          >
                            <span>{{ item.text }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <base-app-dialog-action-button
          @click="update"
        >
          Update
        </base-app-dialog-action-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { userRoleEnums } from '@/constants'
  import { mapGetters } from 'vuex'
  import { eventBus, busEvents } from '@/EventBus'
  import moment from 'moment'
  import UserProfileTab from './UserProfileTab.vue'
  export default {
    name: 'EmployeeEditDialog',
    components: { UserProfileTab },
    props: {
      uProfile: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data () {
      return {
        newRoles: null,
        newAgency: null,
        tab: 0,
        valid: false,
        agencyRoles: userRoleEnums,
        agencies: null,
        orgAgencies: null,
        userProfile: this.uProfile ? JSON.parse(JSON.stringify(this.uProfile)) : {},
      }
    },
    computed: {
      ...mapGetters({
        getAgencyByUuid: 'agency/AGENCY_BY_UUID',
        allAgencies: 'agency/AGENCIES',
      }),
      agenciesWithRoles () {
        return this.agencies
          .filter(e => e.roles.length)
          .sort((a, b) => a.name.localeCompare(b.name))
      },
      sortedAgencies () {
        return this.allAgencies
          .filter(e => e.name)
          .sort((a, b) => a.name.localeCompare(b.name))
      },
    },
    mounted () {
    },
    created () {
      this.initRoles()
    },
    methods: {
      formatDate (value, format) {
        if (value) {
          return moment(value).format(format)
        }
        return ''
      },
      initRoles () {
        this.agencies = this.uProfile.roles.reduce((acc, item) => {
          const agency = this.getAgencyByUuid(item.agencyUuid)
          const role = this.agencyRoles.find(e => e.text === item.name)
          if (!role) return acc
          const roleId = role.value

          const ag = acc.find(e => e.id === agency.id)
          if (ag) ag.roles.push(roleId)
          else {
            acc.push(
              {
                roles: [roleId],
                id: agency.id,
                name: agency.name,
              })
          }
          return acc
        }, [])
        this.orgAgencies = this.uProfile.roles.reduce((acc, item) => {
          const agency = this.getAgencyByUuid(item.agencyUuid)
          const role = this.agencyRoles.find(e => e.text === item.name)
          if (!role) return acc
          const roleId = role.value

          const ag = acc.find(e => e.id === agency.id)
          if (ag) ag.roles.push(roleId)
          else {
            acc.push(
              {
                roles: [roleId],
                id: agency.id,
                name: agency.name,
              })
          }
          return acc
        }, [])
      },
      update () {
        const data = []
        this.agencies.forEach(a => {
          a.roles.forEach(role => {
            data.push({ agencyId: a.id, roleId: role })
          })
        })

        const orgData = []
        this.orgAgencies.forEach(a => {
          a.roles.forEach(role => {
            orgData.push({ agencyId: a.id, roleId: role })
          })
        })

        // filter what is removed and added compared to original data
        const add = data.filter(o => !orgData.some(i => (i.agencyId === o.agencyId && i.roleId === o.roleId)))
        const remove = orgData.filter(o => !data.some(i => (i.agencyId === o.agencyId && i.roleId === o.roleId)))

        const req = {
          add,
          remove,
        }

        this.$careConnect.updateUserAgencyRoles(this.uProfile.id, req)
          .then(resp => {
            const text = 'User roles updated'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'success', text })
            this.close()
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to update user'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
          })
      },
      close () {
        this.$emit('close')
      },
      addRoles () {
        const agency = this.allAgencies.find(e => e.id === this.newAgency)
        this.agencies.push({
          id: this.newAgency,
          name: agency.name,
          roles: this.newRoles,
        })
        // Update roles
        // Nullify select fields
        this.newAgency = null
        this.newRoles = null
      },
    },
  }
</script>
